import React, { FC } from "react";
import { useTranslationWrapper } from "../../../../../../hooks/use-translation-wrapper";
import { Box, Grid, useTheme } from "@mui/material";
import { FieldArray, Form, useFormikContext } from "formik";
import { Heading4, ParagraphSmallBold, useGlobalModal } from "@likemagic-tech/sv-magic-library";
import { ManualCheckinMainGuestForm, ManualCheckinTravelBuddyForm } from "./../components";
import { makeStyles } from "tss-react/mui";
import { useManualCheckinSteps } from "../../../../../../hooks/use-manual-checkin-steps";
import { ManualWizardStepItem } from "../../../../components/manual-wizard/manual-wizard-step-item";
import {
  FrequentFlyerProgram,
  RegistrationCardFormDataType
} from "../../../../../../domain/registration-card";
import { useManualCheckinId } from "../../../use-manual-checkin-id";
import { transformRegistrationCardData } from "../../../../../../graphql/transform/transform-registration-card";
import { useUpdateRegistrationCardGuestsMutationEnhanced } from "../../../../../../graphql/mutations/enhanced-mutations/update-registration-card-guests-enhanced";
import { ManualWizardStepItemNavigation } from "../../../../components/manual-wizard/manual-wizard-step-item-navigation";
import { CheckboxIcon } from "../../../../../../components/icons/CheckboxIcon";
import { openBanner } from "../../../../../../slices/banner.slice";
import { useDispatch } from "../../../../../../store";
import { getYesNoModalArg } from "../../../../../../utils/modal-util";
import { useBackofficeAddMembershipToProfileMutation } from "../../../../../../graphql/mutations/backoffice-add-membership-to-profile.generated";
import { MemberShipClassEnum } from "../../../../../../graphql/generated/graphql";

const useStyles = makeStyles()((theme) => ({
  mainGuest: {
    marginBottom: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(1),
    height: 24
  }
}));

interface ManualCheckinStepRegistrationCardFormProps {
  isConfirmedIndicatorShown: boolean;
  setIsConfirmedIndicatorShown: (value: boolean) => void;
  disabled: boolean;
}

export const ManualCheckinStepRegistrationCardForm: FC<
  ManualCheckinStepRegistrationCardFormProps
> = ({ isConfirmedIndicatorShown, disabled, setIsConfirmedIndicatorShown }) => {
  const { t } = useTranslationWrapper();
  const { classes } = useStyles();
  const { errors, handleBlur, values, dirty, handleSubmit } =
    useFormikContext<RegistrationCardFormDataType>();
  const { nextStep } = useManualCheckinSteps();
  const { manualCheckinId } = useManualCheckinId();
  const [updateRegistrationCardGuest] = useUpdateRegistrationCardGuestsMutationEnhanced();
  const dispatch = useDispatch();
  const { open: openModal } = useGlobalModal();
  const theme = useTheme();

  const [addMembershipToProfile] = useBackofficeAddMembershipToProfileMutation();

  const handleFrequentFlyerPrograms = async (frequentFlyerPrograms: FrequentFlyerProgram[]) => {
    const newFrequentFlyerProgramsNotEmpty = frequentFlyerPrograms.filter(
      (program: any) =>
        !program.existing && program.frequentFlyerNumber && program.frequentFlyerProgram
    );

    await Promise.all(
      newFrequentFlyerProgramsNotEmpty.map((program) =>
        addMembershipToProfile({
          membershipAccount: {
            membershipId: program.frequentFlyerNumber,
            membershipTypeCode: program.frequentFlyerProgram,
            membershipClass: MemberShipClassEnum.FrequentFlyer
          },
          pmsReservationId: values.reservationId,
          pmsProfileId: values.mainGuest.id
        })
      )
    );
  };

  const handleNextStep = async () => {
    handleSubmit();
    if (Object.keys(errors).length === 0) {
      const result =
        !isConfirmedIndicatorShown &&
        (await openModal(
          getYesNoModalArg(
            t("labels__are_you_sure_you_want_to_skip"),
            t("labels__registration_card_not_confirmed"),
            t("buttons__go_to_next_step"),
            t("buttons__cancel")
          )
        ));

      if (result || isConfirmedIndicatorShown) {
        if (dirty) {
          await updateRegistrationCardGuest(
            transformRegistrationCardData(values, manualCheckinId ?? "")
          );
          await handleFrequentFlyerPrograms(values.mainGuest.frequentFlyerPrograms);
        }
        nextStep();
      }
    }
  };

  return (
    <>
      <ManualWizardStepItem>
        <Box display="flex" justifyContent="center" flexDirection="column">
          <Form noValidate>
            <Grid container className={classes.mainGuest}>
              <Heading4>{t("labels__wizard_main_guest")}</Heading4>
              {isConfirmedIndicatorShown && (
                <Box
                  sx={{
                    display: "flex",
                    backgroundColor: theme.palette.success.light,
                    color: theme.palette.success.dark,
                    alignItems: "center",
                    gap: 0.5,
                    px: 1.25,
                    height: "fit-content",
                    borderRadius: theme.spacing(0.5)
                  }}
                >
                  <CheckboxIcon />
                  <ParagraphSmallBold
                    sx={{
                      color: theme.palette.success.dark
                    }}
                  >
                    {t(`labels__data_confirmed`)}
                  </ParagraphSmallBold>
                </Box>
              )}
            </Grid>
            <ManualCheckinMainGuestForm disabled={disabled} />

            <FieldArray
              name="travelBuddies"
              render={(props) => (
                <ManualCheckinTravelBuddyForm
                  push={props.push}
                  remove={props.remove}
                  form={props.form}
                  handleBlur={handleBlur}
                  disabled={disabled}
                />
              )}
            />
          </Form>
        </Box>
      </ManualWizardStepItem>

      <ManualWizardStepItemNavigation
        onNextStep={handleNextStep}
        onPreviousStep={async () => {
          if (dirty) {
            handleSubmit();
            if (Object.keys(errors).length === 0) {
              setIsConfirmedIndicatorShown(false);
              await updateRegistrationCardGuest(
                transformRegistrationCardData(values, manualCheckinId ?? "")
              ).unwrap();
              await handleFrequentFlyerPrograms(values.mainGuest.frequentFlyerPrograms);

              dispatch(
                openBanner({
                  type: "success",
                  title: t("labels__saved_successfully")
                })
              );
            }
          }
        }}
        backLabel={t("buttons__save")}
        disableNext={disabled}
        disablePrevious={disabled}
      />
    </>
  );
};
