import { MagicFileData } from "src/types/magic-file";

export type FileWithPreview = { preview: string } & File;
export type MagicFileDataWithPreview = { preview: string } & MagicFileData;

export const assignPreviewToFiles = (files: File[] | null): FileWithPreview[] | null => {
  if (!files || !files.length) {
    return null;
  }

  return files.map((file) => {
    const { name, lastModified, size, type } = file;
    const preview = URL.createObjectURL(file);

    return { name, lastModified, size, type, preview } as FileWithPreview;
  });
};

export const assignPreviewToFile = (
  file: MagicFileData | null
): FileWithPreview | MagicFileDataWithPreview | null => {
  if (!file) {
    return null;
  }

  return { ...file, preview: file.fileSrc };
};

export async function dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();
  return new File([blob], fileName, { type: "image/jpeg" });
}

export function getBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}

export function isPdf(contentType?: string) {
  return contentType === "application/pdf";
}

export const openBase64InNewTab = (base64: string) => {
  const xhr = new XMLHttpRequest();
  xhr.open("GET", base64 ?? "", true);
  xhr.responseType = "blob";
  xhr.onload = () => {
    if (xhr.status === 200) {
      const blob = xhr.response;
      const url = window.URL.createObjectURL(blob);
      window.open(url, "_blank");
    }
  };
  xhr.send();
};

export const downloadFile = (url: string, fileName: string) => {
  const xhr = new XMLHttpRequest();
  xhr.open("GET", url, true);
  xhr.responseType = "blob";
  xhr.onload = () => {
    if (xhr.status === 200) {
      const blob = xhr.response;
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  xhr.send();
};

export const getFileNameFromHeader = (
  response: Response,
  header: string = "Content-Disposition"
) => {
  const fileName = response.headers.get(header)?.match(/filename="(.+)"/);

  console.log("fileName", fileName);

  return fileName && fileName[1] ? fileName[1] : "";
};
